import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Crawler, CrawlerImpl} from "../../interfaces/crawler";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {showAPIError} from "../../utils/api";
import {Seconds} from "../../interfaces/datetime";

@Component({
  selector: 'app-create-crawler-modal',
  templateUrl: './create-crawler-modal.component.html',
  styleUrls: ['./create-crawler-modal.component.scss']
})
export class CreateCrawlerModalComponent implements OnChanges, OnInit {

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<Crawler> = new EventEmitter<Crawler>();

  loading: boolean = false;

  implementations: CrawlerImpl[] = [];
  keysMap = new Map<string, string[]>();

  keys: string[] = [];
  config: {[_:string]: string} = {};

  name = '';
  selectedImplementation: string|null = null;
  interval: Seconds = 0;
  retryInterval: Seconds = 0;
  timeout: Seconds = 0;
  maxRetryCount: number = 0;

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private notification: NzNotificationService) {

  }

  ngOnChanges(changes: SimpleChanges): void { }

  async ngOnInit() {
    try {
      this.loading = true;
      this.implementations = (await this.api.listCrawlerImpl()).implementations;

      this.keysMap.clear();
      for (const impl of this.implementations) {

        const data = await this.api.listCrawlerImplConfigKeys(impl.key)

        this.keysMap.set(impl.key, data.configKeys);

        this.clear();
      }
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  implementationChanged(impl: string) {
    this.keys = this.keysMap.get(impl) ?? [];
    this.config = {};
  }

  close() {
    this.showChange.emit(this.show = false);
    this.clear();
  }

  clear() {
    this.name = '';
    this.selectedImplementation = null;
    this.interval = 86400;
    this.retryInterval = 2700;
    this.timeout = 1800;
    this.maxRetryCount = 2;
  }

  async execute() {

    if (this.show === null) return;

    try {
      this.loading = true;

      let conf = this.config;
      for (const key of Object.keys(conf)) {
        if (conf[key] === '') delete conf[key];
      }

      const data = await this.api.createCrawler({
        'name':            this.name,
        'implementation':  this.selectedImplementation ??'',
        'enabled':         false,
        'interval':        this.interval,
        'retryInterval':   this.retryInterval,
        'timeout':         this.timeout,
        'maxRetryCount':   this.maxRetryCount,
        'configuration':   conf,
      });

      this.showChange.emit(this.show = false);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Crawler konnte nicht erstellt werden', err)
    } finally {
      this.loading = false;
    }
  }
}
