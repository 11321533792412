
<app-default-frame>


  <nz-page-header class="site-page-header" nzTitle="baden online Panel" nzSubtitle="Dashboard"></nz-page-header>


  <div nz-row [nzGutter]="[24, 24]">

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Gruppen" [valueFunc]="queryGroupCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Beiträge" [valueFunc]="queryPostCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Abonennten" [valueFunc]="querySubscriberCount()" ></app-number-card>
    </div>

  </div>

  <div nz-row [nzGutter]="[24, 24]">

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Besucher (heute)" [valueFunc]="queryVisitorCount('today')" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Besucher (Diese Woche)" [valueFunc]="queryVisitorCount('thisweek')" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Besucher (Diesen Monat)" [valueFunc]="queryVisitorCount('thismonth')" ></app-number-card>
    </div>

  </div>

  <div nz-row [nzGutter]="[24, 24]">

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Veranstaltungen" [valueFunc]="queryEventCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Fotos" [valueFunc]="queryPhotoCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Videos" [valueFunc]="queryVideoCount()" ></app-number-card>
    </div>

  </div>


</app-default-frame>

